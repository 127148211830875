import { Button } from "flowbite-react";
import React from "react";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";

const ShowToggle = ({ showFeature, setShowFeatures = () => {} }) => {
  return (
    <Button
      className="text-black outline-none shadow-none border-none"
      onClick={() => setShowFeatures(!showFeature)}
    >
      {showFeature ? (
        <CiCircleMinus size={20} className="mr-1" />
      ) : (
        <CiCirclePlus size={20} className="mr-1" />
      )}
      <span className="text-md font-bold">{showFeature ? "Hide" : "Show"}</span>
    </Button>
  );
};

export default ShowToggle;
