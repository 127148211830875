import { Tooltip } from "flowbite-react";
import React from "react";
import { CiCircleInfo } from "react-icons/ci";

const CustomToolTip = ({ content, size }) => {
  return (
    <Tooltip
      content={content}
      style="light"
      placement="right"
      className="text-xs z-30 break-words whitespace-pre-wrap max-w-48 md:max-w-96 overflow-hidden"
    >
      <CiCircleInfo size={size} className="cursor-pointer" />
    </Tooltip>
  );
};

export default CustomToolTip;
