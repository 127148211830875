import React from "react";

const Toggle = ({ toggle, setToggle = () => {} }) => {
  return (
    <div className="flex items-center">
      <button
        className={`${
          toggle ? "bg-blue100" : "bg-gray100"
        } w-12 h-6 rounded-4xl relative transition-transform duration-700 delay-1000`}
        onClick={() => setToggle(!toggle)}
      >
        <div
          className={`w-5 h-5 bg-white rounded-full absolute ${
            toggle ? "right-1 top-0" : "left-1 top-0"
          }`}
          style={{ marginTop: "2px" }}
        ></div>
      </button>
      <p className="ml-2 text-lg">Show differences</p>
    </div>
  );
};

export default Toggle;
