import axios from "axios";

const instance = axios.create({
  baseURL: "https://1df0-37-60-227-0.ngrok-free.app",
  timeout: 5000000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "ngrok-skip-browser-warning": "true",
    Accept: "application/json",
  },
});

export const get = async (endpoint, params) => {
  return await instance.get(endpoint, params);
};

export const post = async (endpoint, params) => {
  return await instance.post(endpoint, params);
};

export default instance;
