import React, { useState } from "react";
import { Logo } from "../../assets";
import { Button } from "flowbite-react";
import { SearchInput } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { searchTerms } from "../../data";
import { fetchProduct } from "../../services/productApi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.css";
import "../../index.css";

const searchMessage =
  "Shortlisting 3 products for you\n  Fetching factors to consider\n  Analyzing user reviews";

const Search = () => {
  const [searchValue, setSeachValue] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchProducts = async () => {
    try {
      if (searchValue) {
        setLoading(true);
        const products = await fetchProduct(searchValue);
        if (products) {
          const SubjectiveData = JSON.stringify(products?.Subjective);
          const ObjectiveData = JSON.stringify(products?.Objective);
          const productAllFeatures = JSON.stringify(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          localStorage.setItem("SubjectiveData", SubjectiveData);
          localStorage.setItem("ObjectiveData", ObjectiveData);
          localStorage.setItem(
            "SubjectiveProductAllFeatures",
            productAllFeatures
          );
          setLoading(false);
          localStorage.setItem("searchText", searchValue);
          navigate("/dashboard");
        }
      } else {
        new Notify({
          status: "error",
          title: "Error",
          text: "Please Enter a query!",
          effect: "fade",
          speed: 300,
          customClass: null,
          customIcon: null,
          showIcon: true,
          showCloseButton: true,
          autoclose: true,
          autotimeout: 3000,
          gap: 20,
          distance: 20,
          type: "outline",
          position: "right top",
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      new Notify({
        status: "error",
        title: e.name,
        text: e.message,
        effect: "fade",
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: "outline",
        position: "right top",
      });
      setSeachValue("");
    }
  };

  const handleSearch = () => {
    fetchProducts();
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default action of newline in the textarea
      fetchProducts();
    }
  };

  const handleSearchChange = (value) => {
    setSeachValue(value);
  };

  const handleSearchTerm = (value) => {
    setSeachValue(value);
  };
  return (
    <div className="px-3 md-px-5 lg:px-20 py-10 md:py-10 h-screen">
      <Link to="/about" className="text-xl float-right">
        About Us
      </Link>
      <div className="main-container min-h-[600px] flex justify-center items-center">
        <div className="w-12/12 lg:w-10/12">
          <img
            src={Logo}
            alt="logo"
            className="w-[120px] md:w-[200px] mx-auto"
          />
          <p className="text-2xl lg:text-3xl font-light text-center py-4 lg:py-3 leading-10">
            Authentic comparison tables
          </p>
          <div className="grid grid-cols-12 gap-5 items-center justify-between">
            <div className="col-span-12 md:col-span-9">
              <SearchInput
                icon={true}
                value={searchValue}
                placeholder={"Search"}
                disabled={loading}
                handleSearch={handleSearchOnEnter}
                handleChange={handleSearchChange}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <Button
                type="submit"
                onClick={handleSearch}
                className="btn bg-black hover:bg-black w-full md:w-52 py-2 mx-auto rounded-[50px]"
              >
                {loading ? (
                  <div className="flex items-center">
                    <AiOutlineLoading3Quarters className="mr-2 loaderIcon" />
                    loading...
                  </div>
                ) : (
                  "Search"
                )}
              </Button>
            </div>
          </div>

          <div className="search-suggestions-grid grid grid-cols-12 gap-5 mt-5 md:mt-0">
            {searchTerms?.map((item, index) => (
              <Button
                key={index}
                type="submit"
                onClick={() => handleSearchTerm(item)}
                className="search-suggestions bg-gray-200 col-span-12 md:col-span-4 text-gray-600 hover:bg-gray100 mt-0 md:mt-10 rounded-[50px] border-2 border-white"
              >
                <p>{item}</p>
              </Button>
            ))}
          </div>

          {loading ? (
            <div className="mt-5 flex justify-center">
              <div>
                {searchMessage.split("\n").map((line, index) => (
                  <p
                    key={index}
                    className="text-left text-sm text-blue100 leading-6"
                  >
                    -{" "}
                    <AiOutlineLoading3Quarters
                      size={13}
                      className="mx-1 loaderIcon inline"
                    />
                    {line}
                    <br />
                  </p>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="w-12/12 lg:w-9/12 mx-auto">
        <p className="text-lg md:text-xl font-light leading-10 pr-0 md:pr-10 text-center md:text-left">
          Our search results are not sponsored by any brand or retailer. We may
          earn affiliate commissions when you complete a purchase on Amazon.
          <a href="/about" className="text-lg ml-1 underline">
            Learn more
          </a>
        </p>
      </div>
    </div>
  );
};

export default Search;
