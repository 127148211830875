import { Checkbox, Label } from "flowbite-react";
import React from "react";
import CustomToolTip from "../CustomToolTip";

const SelectInput = ({
  key,
  data,
  topTableFeatures,
  handleFeature = () => {},
}) => {
  const formattedFeature = data.feature.replace(/[_-]/g, " ");
  const isChecked = topTableFeatures?.some(
    (item) => item?.feature.replace(/[_-]/g, " ") === formattedFeature
  );

  return (
    <div className="flex items-center justify-between gap-2 py-3" key={key}>
      <div className="flex items-center">
        <Checkbox
          id={key}
          className="cursor-pointer"
          checked={isChecked}
          onClick={() => handleFeature(data)}
        />
        <Label
          htmlFor="agree"
          className="flex text-xs md:text-sm pl-3 text-wrap"
        >
          {formattedFeature}
        </Label>
      </div>
      <CustomToolTip content={data?.description} size={14} />
    </div>
  );
};

export default SelectInput;
