import { Iron2Img, Iron3Img, IronImg } from "./assets";

export const features = [
  {
    id: 1,
    checked: false,
    label: "Ironing fabric",
    info: "Automatically turns off the iron when left unattended, a critical safety feature",
  },
  {
    id: 2,
    checked: false,
    label: "Soleplate material",
    info: "Soleplate material",
  },
  {
    id: 3,
    checked: false,
    label: "Steam strength",
    info: "Steam strength",
  },
  {
    id: 4,
    checked: false,
    label: "Buttoned shirts",
    info: "Buttoned shirts",
  },
  {
    id: 5,
    checked: false,
    label: "Heat",
    info: "Heat",
  },
  {
    id: 6,
    checked: false,
    label: "Comfortable hold",
    info: "Automatically turns off the iron when left unattended, a critical safety feature",
  },
  {
    id: 7,
    checked: false,
    label: "Cord",
    info: "Cord",
  },
  {
    id: 8,
    checked: false,
    label: "Digital display",
    info: "Digital display",
  },
  {
    id: 9,
    checked: false,
    label: "Weight",
    info: "Automatically turns off the iron when left unattended, a critical safety feature",
  },
  {
    id: 10,
    checked: false,
    label: "Auto-shutoff",
    info: "Auto-shutoff",
  },
  {
    id: 11,
    checked: false,
    label: "Wattage",
    info: "Wattage",
  },
  {
    id: 12,
    checked: false,
    label: "Warranty",
    info: "Automatically turns off the iron when left unattended, a critical safety feature",
  },
];

export const mainFeatures = [
  {
    id: 1,
    title: "Low to medium heat setting",
    text: "An iron around 275°F prevents damage to spandex fibers",
  },
  {
    id: 2,
    title: "Steam Function",
    text: "Good steam output and control smooths wrinkles safely",
  },
  {
    id: 3,
    title: "Non-Stick Soleplate",
    text: "Avoids sticking and fiber damage",
  },
  {
    id: 4,
    title: "Precision Tip",
    text: "Essential for detailed areas like buttons and zippers",
  },
];

export const tableHeaders = [
  {
    id: 1,
    product: "Rowenta",
    image: IronImg,
  },
  {
    id: 2,
    product: "CHI",
    image: Iron2Img,
  },
  {
    id: 3,
    product: "Black & Decker",
    image: Iron3Img,
  },
];

export const tableData = [
  {
    id: 1,
    rating: "Cotton / spandex fabric",
    product1: 71,
    product2: 42,
    product3: 78,
  },
  {
    id: 2,
    rating: "Leakage",
    product1: 71,
    product2: 87,
    product3: 42,
  },
  {
    id: 2,
    rating: "Comfortable Hold",
    product1: 52,
    product2: 69,
    product3: 42,
  },
];

export const tableDetailData = [
  {
    id: 1,
    rating: "Wattage",
    product1: 1725,
    product2: 1700,
    product3: 1200,
  },
  {
    id: 2,
    rating: "Wrinkle Free",
    product1: 3.9,
    product2: 4.4,
    product3: "-",
  },
  {
    id: 3,
    rating: "Temperature Control",
    product1: 4.4,
    product2: 4.4,
    product3: 4.4,
  },
  {
    id: 4,
    rating: "Material",
    product1: "Stainless Steel",
    product2: "Ceramic",
    product3: "Stainless Steel",
  },
  {
    id: 5,
    rating: "Weight",
    product1: 3.9,
    product2: 4.4,
    product3: "-",
  },
];

export const positiveReviews = [
  "It's a good, heft weighty, which I find easier to use. You don't have to push so hard to smooth the fabric",
  "Ergonomic, quality materials and big capacity, heavy but easy to handle, very recommended",
  "Ergonomic, quality materials and big capacity, heavy but easy to handle, very recommended",
];
export const negativeReviews = [
  "Impossible for the cord to reach a plug and be usable to iron at the same time, not recommended ",
  "Impossible for the cord to reach a plug and be usable to iron at the same time, not recommended",
  "Impossible for the cord to reach a plug and be usable to iron at the same time, not recommended",
];

export const searchTerms = [
  "steam iron for travel",
  "clothing iron with auto shut-off",
  "steam iron for buttoned shirts",
];
