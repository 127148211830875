import { createBrowserRouter } from "react-router-dom";
import { AboutUs, Dashboard, Search } from "./pages";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Search />,
  },
  {
    path: "/about",
    element: <AboutUs />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
]);

export default routes;
