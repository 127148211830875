import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="w-12/12 md:w-11/12 mx-auto px-5 py-5 md:py-20">
      <h4 className="text-lg md:text-xl flex items-center">
        <Link to="/">
          <IoIosArrowBack size={25} className=" mr-1" />
        </Link>
        About Us
      </h4>
      <div className="my-16">
        <p className="text-lg md:text-xl font-light leading-10 py-5">
          Donna’s mission is to use technology to enhance your decision
          productivity. We serve as a comprehensive product review and
          comparison platform for consumers looking to make higher-consideration
          purchases for their homes.
        </p>
        <p className="text-lg md:text-xl font-light leading-10 py-5">
          We scour thousands of product reviews from users and experts, allowing
          consumers to research and compare different options. Our key value
          proposition is to help you uncover buying considerations that are
          critical to your decision but may not be top of mind.
        </p>

        <p className="text-lg md:text-xl font-light leading-10 py-5">
          We aim to be the #1 trusted, one-stop resource for consumers to
          discover, evaluate, and purchase the right products based on detailed
          comparisons of user and expert reviews.
        </p>
        <p className="text-lg md:text-xl font-light leading-10 py-5">
          <h3 className="text-lg font-bold"> Why should you trust us?</h3>
          We host over 1 million authentic reviews from verified buyers,
          providing unbiased feedback on products. Vendors cannot pay to remove
          negative reviews, enhancing credibility.
        </p>
        <p className="text-lg md:text-xl font-light leading-10 py-5">
          <h3 className="text-lg font-bold"> How we make money?</h3>
          We earn money through affiliate marketing programs, which means we
          might receive commissions on products purchased through our links to
          retailer sites. But don’t worry, we have no incentive to choose
          inferior products or succumb to pressure from manufacturers. We strive
          to ensure that you never have buyer’s remorse. The products we feature
          on our site are always chosen based on our high standards of analyzing
          unbiased reviews, never influenced by affiliate deals or advertising
          relationships.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
