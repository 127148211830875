import React from "react";
import { AmazonImg } from "../../assets";
import { Button } from "flowbite-react";
import "react-circular-progressbar/dist/styles.css";
import CustomProgressBar from "../CustomProgressBar";
import "../../index.css";
import { RxCross2 } from "react-icons/rx";

const ProductModal = ({ product, productDetails, handleClose = () => {} }) => {
  return (
    <div className="relative px-1 md:px-4">
      <RxCross2
        size={25}
        color="#D9D9D9"
        className="text-center absolute -top-3 -right-2 md:right-0 cursor-pointer"
        onClick={() => handleClose({ open: false })}
      />
      <div className="grid grid-cols-12 gap-3 md:gap-5">
        <div className="col-span-7 md:col-span-3 md:flex items-center mr-3 md:mr-auto">
          <img
            src={product?.product_image}
            className="w-[70px] md:w-[100px]"
            alt="product"
          />
          <div className="flex items-center mt-2 md:mt-0 md:ml-4">
            <p className="text-sm md:text-md text-center font-semibold">
              {product?.name}
            </p>
            <a
              href={productDetails.customer_review_page}
              target="_blank"
              className="text-blue100 capitalize text-sm inline-block "
            >
              <p className="ml-4 underline text-sm md:text-md">
                4.5<span>*</span>
              </p>
            </a>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 md:flex justify-between items-center order-2 md:order-1">
          <div>
            <Button className="bg-blue100 font-light md:font-semibold px-0 md:px-3 py-0 md:py-1 ml-0 md:ml-5">
              <p className="text-xs md:text-sm">
                {productDetails.feature?.replace(/[_-]/g, " ")}
              </p>
            </Button>
          </div>
          <div className="flex justify-between mt-2 md:mt-0 w-12/12 md:w-6/12">
            <div>
              <h3 className="text-center text-sm md:text-md font-semibold">
                {productDetails.positive + productDetails.negative}
              </h3>
              <p className="text-sm md:text-md">mentions</p>
            </div>
            <div>
              <h3 className="text-center text-sm md:text-md font-semibold text-green100">
                {productDetails.positive}
              </h3>
              <p className="text-sm md:text-md text-green100">positive</p>
            </div>
            <div>
              <h3 className="text-center text-sm md:text-md font-semibold text-red100">
                {productDetails.negative}
              </h3>
              <p className="text-sm md:text-md text-red100">negative</p>
            </div>
          </div>
        </div>
        <div className="col-span-5 md:col-span-3 flex justify-end mr-5 order-1 md:order-2">
          <CustomProgressBar
            positivePercent={Math.floor(
              (productDetails.positive /
                (productDetails.positive + productDetails.negative)) *
                100
            )}
            negativePercent={Math.floor(
              (productDetails.negative /
                (productDetails.positive + productDetails.negative)) *
                100
            )}
          />
        </div>
      </div>
      <p className="text-sm md:text-md font-extralight py-2">
        {productDetails.summary}
      </p>
      <a href={productDetails.customer_review_page} target="_blank">
        <img src={AmazonImg} alt="amazon" className="w-[60px] md:w-[70px]" />
      </a>
      <div className="grid grid-cols-12 gap-6 md:gap-10">
        <div className="col-span-12 md:col-span-6">
          <div className="border-2 rounded-4xl p-3 border-gray100 min-h-[18rem]">
            <p className="text-md font-semibold text-green100">
              Positive Reviews
            </p>
            {productDetails.positive_reviews?.map((item, index) => (
              <>
                <p
                  key={index}
                  className="text-sm py-3 text-ellipsis overflow-hidden max-h-14"
                >
                  "...{item}
                </p>
                <span className="inline">..."</span>
                <a
                  href={productDetails.customer_review_page}
                  target="_blank"
                  className="text-blue100 capitalize text-sm inline-block "
                >
                  read more
                </a>
              </>
            ))}
          </div>
        </div>
        <div className="col-span-12 md:col-span-6">
          <div className="border-2 rounded-4xl p-3 border-gray100 min-h-[18rem]">
            <p className="text-md font-semibold text-red100">
              Negative Reviews
            </p>
            {productDetails.negative_reviews?.map((item, index) => (
              <>
                <p
                  key={index}
                  className="text-sm py-3 text-ellipsis overflow-hidden max-h-14"
                >
                  "...{item}
                </p>
                <span className="inline">..."</span>
                <a
                  href={productDetails.customer_review_page}
                  target="_blank"
                  className="text-blue100 capitalize text-sm inline-block "
                >
                  read more
                </a>
              </>
            ))}
          </div>
        </div>
      </div>
      {product?.awards?.length ? (
        <p className="text-md font-semibold mt-2">Ranked by Experts:</p>
      ) : null}
      <div
        className={`flex justify-between ${
          product?.awards?.length === 1
            ? "md:justify-start"
            : "md:justify-around"
        } items-center flex-wrap`}
      >
        {product?.awards?.map((award, index) => (
          <div className="flex items-center" key={index}>
            <img
              src={award.award_image}
              className="w-[60px] md:w-[80px]"
              alt={award.brand}
            />
            <a href={award.award_given_link} target="_blank">
              <p className="text-sm md:text-md font-semibold ml-2 md:ml-4 underline">
                {award.award_title}
              </p>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductModal;
