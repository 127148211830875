import autosize from "autosize";
import React, { useEffect, useRef } from "react";
import { CiSearch } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";
import { FaArrowUp } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import "../../index.css";

const SearchInput = ({
  type,
  icon,
  value,
  placeholder,
  disabled,
  crossIcon,
  searchIcon,
  handleChange = () => {},
  handleSearchClick = () => {},
  handleSearch = () => {},
  handleCross = () => {},
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      autosize(textareaRef.current);
    }
  }, []);

  const style = {
    minHeight: "38px",
    resize: "none",
    padding: "9px",
    boxSizing: "border-box",
    fontSize: "15px",
    border: "none",
    outline: "none",
    boxShadow: "none",
  };
  return (
    <div
      className={`${
        type === "text"
          ? "w-12/12 md:w-8/12 lg:w-5/12 rounded-[10px]"
          : "rounded-[50px]"
      } bg-white px-3 py-1 md:py-2 flex items-center my-2 shadow-md`}
    >
      {icon ? <CiSearch size={30} color="#C5C5C5" /> : null}
      <textarea
        style={style}
        ref={textareaRef}
        rows={1}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        className="w-full border-0"
        defaultValue=""
        disabled={disabled}
        onKeyDown={(e) => handleSearch(e)}
      />
      {crossIcon ? (
        <RxCross2
          size={25}
          color="#D9D9D9"
          onClick={handleCross}
          className="cursor-pointer "
        />
      ) : searchIcon ? (
        <div className="border border-[#cccccc] bg-[#cccccc] rounded-full w-12 h-10 flex justify-center items-center">
          {disabled ? (
            <AiOutlineLoading3Quarters
              size={20}
              color="white"
              className="mx-auto loaderIcon2"
            />
          ) : (
            <FaArrowUp
              size={18}
              color="white"
              onClick={handleSearchClick}
              className="cursor-pointer"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
