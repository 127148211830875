import React, { useEffect, useRef, useState } from "react";
import { Logo } from "../../assets";
import {
  CompareTable,
  SearchInput,
  SelectInput,
  ShowToggle,
} from "../../components";
import { Button } from "flowbite-react";
import { RiPrinterFill } from "react-icons/ri";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.css";
import { Link } from "react-router-dom";
import { fetchProduct } from "../../services/productApi";
import { useReactToPrint } from "react-to-print";
import { FaSpinner } from "react-icons/fa";
import { VscThreeBars } from "react-icons/vsc";

const formatString = (str) => {
  return str.replace(/[_-]/g, " ");
};

const Dashboard = () => {
  const [showFeature, setShowFeatures] = useState(true);
  const [SubjectiveData, setSubjectiveData] = useState({});
  const [ObjectiveData, setObjectiveData] = useState({});
  const [searchValue, setSeachValue] = useState("");
  const [featuresSearcValue, setFeaturesSearcValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [crossIcon, setCrossIcon] = useState(false);
  const [searchIcon, setSearchIcon] = useState(false);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [sidebarFeatures, setSidebarFeatures] = useState([]);
  const [topTableFeatures, setTopTableFeatures] = useState([]);
  const [featuresSearchOpen, setFeaturesSearchOpen] = useState(false);

  const updateData = () => {
    const Subjective = JSON.parse(localStorage.getItem("SubjectiveData"));
    const Objective = JSON.parse(localStorage.getItem("ObjectiveData"));
    setSubjectiveData(Subjective);
    const searchText = localStorage.getItem("searchText");
    if (searchText?.length) {
      setCrossIcon(true);
    }
    setSeachValue(searchText);
    const featureMap = new Map();

    const combinedArray = [
      ...Subjective?.features_on_sidebar,
      ...Subjective?.top_4_features,
      ...topTableFeatures,
    ];
    combinedArray.forEach((item) => {
      if (!featureMap.has(item.feature) || item.description) {
        featureMap.set(item.feature, item.description);
      }
    });

    const uniqueFeatures = Array.from(featureMap, ([feature, description]) => ({
      feature,
      description,
    }));

    setSidebarFeatures(uniqueFeatures);
    setTopTableFeatures(Subjective?.top_4_features);
    setObjectiveData(Objective);
  };

  useEffect(() => {
    updateData();
  }, []);

  useEffect(() => {}, []);

  const handleSearchChange = (value) => {
    setSeachValue(value);
    if (value) {
      setSearchIcon(true);
    } else {
      setSearchIcon(false);
    }
  };

  const fetchProducts = async () => {
    try {
      if (searchValue) {
        setLoading(true);
        localStorage.removeItem("searchText");
        const products = await fetchProduct(searchValue);
        if (products) {
          const SubjectiveData = JSON.stringify(products?.Subjective);
          const ObjectiveData = JSON.stringify(products?.Objective);
          const productAllFeatures = JSON.stringify(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          localStorage.setItem("SubjectiveData", SubjectiveData);
          localStorage.setItem("ObjectiveData", ObjectiveData);
          localStorage.setItem(
            "SubjectiveProductAllFeatures",
            productAllFeatures
          );
          setLoading(false);
          localStorage.setItem("searchText", searchValue);
          updateData();
          new Notify({
            status: "success",
            title: "Success",
            text: "New Product data is Fetched Successfully!",
            effect: "fade",
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: true,
            autotimeout: 3000,
            gap: 20,
            distance: 20,
            type: "outline",
            position: "right top",
          });
        }
      } else {
        new Notify({
          status: "error",
          title: "Error",
          text: "Please Enter a query!",
          effect: "fade",
          speed: 300,
          customClass: null,
          customIcon: null,
          showIcon: true,
          showCloseButton: true,
          autoclose: true,
          autotimeout: 3000,
          gap: 20,
          distance: 20,
          type: "outline",
          position: "right top",
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      new Notify({
        status: "error",
        title: e.name,
        text: e.message,
        effect: "fade",
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: "outline",
        position: "right top",
      });
      setSeachValue("");
    }
  };
  const handleSearch = () => {
    fetchProducts();
  };

  const handleFeatureSearch = (value) => {
    setFeaturesSearcValue(value);
    const filteredFeatures = SubjectiveData?.descriptions.filter((item) => {
      const formattedFeature = formatString(item.feature).toLowerCase();
      const formattedDescription = formatString(item.description).toLowerCase();
      const searchValue = value.toLowerCase();
      if (searchValue?.length) {
        return (
          formattedFeature.includes(searchValue) ||
          formattedDescription.includes(searchValue)
        );
      }
    });

    if (filteredFeatures?.length) {
      setFilteredFeatures(filteredFeatures);
    } else {
      setFilteredFeatures([]);
    }
  };

  const handleFeatureCheck = (checkedFeature) => {
    let newData = [];
    if (
      topTableFeatures?.some(
        (item) => item?.feature === checkedFeature?.feature
      )
    ) {
      setTopTableFeatures(
        topTableFeatures?.filter(
          (item) => item?.feature !== checkedFeature?.feature
        )
      );
    } else {
      newData.push(...topTableFeatures, checkedFeature);
      setTopTableFeatures(newData);
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSearchTextDelete = () => {
    localStorage.removeItem("searchText");
    setCrossIcon(false);
    setSeachValue("");
  };

  const handleSearchOnEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      fetchProducts();
    }
  };

  return (
    <div className="p-4 md:p-8">
      <div className="grid grid-cols-12 gap-2 md:gap-5">
        <div className="col-span-12 md:col-span-4 lg:col-span-2">
          <Link to="/">
            <img
              src={Logo}
              alt="logo"
              className="w-[80px] md:w-[200px] mx-auto mb-0 md:mb-5"
            />
          </Link>
          <div
            className="absolute top-12 cursor-pointer visible md:hidden"
            onClick={() => setFeaturesSearchOpen(!featuresSearchOpen)}
          >
            <VscThreeBars size={28} />
          </div>
          <div
            className={`${
              featuresSearchOpen ? "visible" : "hidden"
            } bg-white rounded-2xl shadow-md overflow-hidden break-words md:hidden absolute top-[5.5rem] z-20`}
          >
            <h3 className="bg-gray200 py-2 text-center text-sm">
              Factors to consider
            </h3>
            <div className="px-3 py-4">
              <input
                value={featuresSearcValue}
                className="border border-[#D9D9D9] w-full rounded-xl text-sm py-1 px-2 mb-1 outline-none"
                onChange={(e) => handleFeatureSearch(e.target.value)}
                placeholder="Search features"
              />
              {featuresSearcValue?.length && filteredFeatures?.length ? (
                filteredFeatures?.map((item, key) => (
                  <SelectInput
                    key={key}
                    data={item}
                    topTableFeatures={topTableFeatures}
                    handleFeature={handleFeatureCheck}
                  />
                ))
              ) : !featuresSearcValue?.length &&
                filteredFeatures?.length < 1 ? (
                sidebarFeatures?.map((item, key) => (
                  <SelectInput
                    key={key}
                    data={item}
                    topTableFeatures={topTableFeatures}
                    handleFeature={handleFeatureCheck}
                  />
                ))
              ) : (
                <div className="text-center my-5 text-gray-600">No Results</div>
              )}
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-md overflow-hidden hidden md:block">
            <h3 className="bg-gray200 py-4 text-center text-lg">
              Factors to consider
            </h3>
            <div className="px-3 py-4">
              <input
                value={featuresSearcValue}
                className="border border-[#D9D9D9] w-full rounded-xl text-md py-2 px-2 mb-3 outline-none"
                onChange={(e) => handleFeatureSearch(e.target.value)}
                placeholder="Search features"
              />
              {featuresSearcValue?.length && filteredFeatures?.length ? (
                filteredFeatures?.map((item, key) => (
                  <SelectInput
                    key={key}
                    data={item}
                    topTableFeatures={topTableFeatures}
                    handleFeature={handleFeatureCheck}
                  />
                ))
              ) : !featuresSearcValue?.length &&
                filteredFeatures?.length < 1 ? (
                sidebarFeatures?.map((item, key) => (
                  <SelectInput
                    key={key}
                    data={item}
                    topTableFeatures={topTableFeatures}
                    handleFeature={handleFeatureCheck}
                  />
                ))
              ) : (
                <div className="text-center my-5 text-gray-600">No Results</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-8 lg:col-span-10 pt-0  md:pt-16">
          <div className="grid grid-cols-12 gap-5 w-12/12 lg:w-8/12 items-center">
            <div className="col-span-12 lg:col-span-8">
              <SearchInput
                icon={true}
                crossIcon={crossIcon}
                placeholder="Search"
                value={searchValue}
                disabled={loading}
                handleSearch={handleSearchOnEnter}
                handleChange={handleSearchChange}
                handleCross={handleSearchTextDelete}
                handleSearchClick={handleSearch}
                searchIcon={searchIcon}
              />
            </div>
            <div className="col-span-12 md:col-span-4 hidden lg:block">
              <Button
                type="submit"
                onClick={handleSearch}
                className="btn bg-[#b7b7b7ff] border-2 border-white text-black w-full md:w-44 py-1 rounded-[50px]"
              >
                <p className="text-xl">
                  {loading ? (
                    <div className="flex items-center">
                      <FaSpinner className="mr-2" />
                      loading...
                    </div>
                  ) : (
                    "Search"
                  )}
                </p>
              </Button>
            </div>
          </div>
          <div className="bg-gray200 border-2 border-white rounded-2xl shadow-md px-2 md:px-5 pt-8 pb-2 lg:py-2 relative mt-3 md:mt-5">
            <p className="text-sm md:text-md py-1">
              <span className="px-0 md:px-2 text-sm md:text-lg font-semibold">
                What to consider:{" "}
              </span>
              For your query consider the following features -
            </p>
            {showFeature ? (
              <div className="grid grid-cols-12 gap-4 md:gap-8">
                {SubjectiveData?.top_4_features?.length ? (
                  SubjectiveData?.top_4_features?.map((item, index) => (
                    <div className="col-span-12 lg:col-span-3 lg:py-2">
                      <div className="flex items-start">
                        <div className="rounded-md bg-[#666666] border border-white p-1 md:p-2 mr-1 md:mr-2 mt-1"></div>
                        <div>
                          <div key={index} className="text-xs md:text-sm">
                            <p className="font-semibold">
                              {item?.feature?.replace(/_/g, " ")}
                            </p>
                            <span className="font-light leading-5">
                              {item?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-span-12 flex justify-center items-center h-16">
                    <p className="text-gray-500 text-sm">
                      No Top Features Found!
                    </p>
                  </div>
                )}
              </div>
            ) : null}
            <div className="absolute top-0 lg:top-2 right-2">
              <ShowToggle
                showFeature={showFeature}
                setShowFeatures={setShowFeatures}
              />
            </div>
          </div>
          <div className="bg-white rounded-2xl shadow-md px-1 md:px-5 py-8 lg:py-4 mt-5 relative overflow-y-auto">
            <h2 className="text-left md:text-center text-lg md:text-xl font-bold pl-4">
              Comparison
            </h2>
            <CompareTable
              tableRef={componentRef}
              SubjectiveData={SubjectiveData}
              ObjectiveData={ObjectiveData}
              topTableFeatures={topTableFeatures}
            />
            <div className="flex justify-center my-2 mt-8 md:mt-4">
              <div className="grid grid-cols-12 gap-3 lg:gap-5">
                <div className="col-span-12 lg:col-span-6 flex items-center justify-start">
                  <p className="mr-4 md:mr-8">Negative</p>
                  <div className="rounded-lg h-10">
                    <span className="inline-block w-8 md:w-10 h-8 md:h-10 bg-red100 rounded-s-lg"></span>
                    <span className="inline-block w-8 md:w-10 h-8 md:h-10 bg-orange100"></span>
                    <span className="inline-block w-8 md:w-10 h-8 md:h-10 bg-yellow200"></span>
                    <span className="inline-block w-8 md:w-10 h-8 md:h-10 bg-green200"></span>
                    <span className="inline-block w-8 md:w-10 h-8 md:h-10 bg-green100 rounded-e-lg"></span>
                  </div>
                  <p className="ml-4 md:ml-8">Positive</p>
                </div>
                <div className="col-span-12 lg:col-span-6 flex items-center justify-center lg:justify-end">
                  <div className="w-10 h-10 bg-gray100 rounded-lg"></div>
                  <p className="ml-4 md:ml-8">Insufficient reviews</p>
                </div>
              </div>
            </div>
            <Button
              className="text-black capitalize absolute top-0 lg:top-2 right-2 outline-none shadow-none border-none hidden md:block"
              onClick={handlePrint}
            >
              <RiPrinterFill size={30} className="ml-2" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
