import React from "react";

import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

function Example(props) {
  return (
    <div className="w-[60px] md:w-[80px]">
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%" }}>{props.children}</div>
      </div>
    </div>
  );
}

const CustomProgressBar = ({ positivePercent, negativePercent }) => {
  return (
    <Example>
      <CircularProgressbarWithChildren
        value={negativePercent}
        strokeWidth={20}
        counterClockwise={true}
        styles={buildStyles({
          pathColor: "#FA1515",
          trailColor: "#eee",
          strokeLinecap: "butt",
        })}
      >
        <CircularProgressbar
          value={positivePercent}
          strokeWidth={20}
          styles={buildStyles({
            trailColor: "transparent",
            pathColor: "#3BB605",
            strokeLinecap: "butt",
          })}
        />
      </CircularProgressbarWithChildren>
      <div className="flex justify-center items-center mt-2">
        <p className="text-red100 text-center text-xs">
          Negative: {negativePercent}%
        </p>
        <p className="text-green100 text-center ml-2 text-xs">
          Positive: {positivePercent}%
        </p>
      </div>
    </Example>
  );
};

export default CustomProgressBar;
