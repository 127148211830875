import service from "./index";

export const fetchProduct = async (data) => {
  const res = await service.get(`/product_data_donna?query=${data}`);
  return res.data;
};

export const showMore = async (productNo) => {
  const res = await service.get(`/show_more?page_num=${productNo}`);
  return res.data;
};
