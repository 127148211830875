/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Table, Modal } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import ShowToggle from "../ShowToggle";
import CustomToolTip from "../CustomToolTip";
import Toggle from "../Toggle";
import ProductModal from "../ProductModal";
import "../../index.css";
import Notify from "simple-notify";
import { showMore } from "../../services/productApi";
import { FaSpinner } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { AmazonlogowhiteImg } from "../../assets";
import { IoIosArrowBack } from "react-icons/io";

const CompareTable = ({
  SubjectiveData,
  topTableFeatures,
  tableRef,
  ObjectiveData,
  SubjectiveFeaturesData,
}) => {
  const [switch1, setSwitch1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [showFeature, setShowFeatures] = useState(false);
  const [
    SubjectiveProductAllFeaturesData,
    setSubjectiveProductAllFeaturesData,
  ] = useState();
  const [ObjectiveFeatureValues, setObjectiveFeatureValues] = useState([]);
  const [FilterFeaturesValue, setFilterFeaturesValue] = useState([]);
  const [openModal, setOpenModal] = useState({
    open: false,
    product: [],
    product_details: [],
  });

  useEffect(() => {
    const productsCorrespondingData = JSON.parse(
      localStorage.getItem("SubjectiveProductAllFeatures")
    );
    setSubjectiveProductAllFeaturesData(productsCorrespondingData);
  }, [SubjectiveData]);

  useEffect(() => {
    if (SubjectiveFeaturesData?.length) {
      setSubjectiveProductAllFeaturesData(SubjectiveFeaturesData);
    }
  }, [SubjectiveFeaturesData]);

  const fetchMore = async () => {
    try {
      if (pageNo < 15) {
        setLoading(true);
        const products = await showMore(pageNo + 1);
        if (products) {
          setSubjectiveProductAllFeaturesData(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          const SubjectiveProductAll = JSON.stringify(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          localStorage.setItem(
            "SubjectiveProductAllFeatures",
            SubjectiveProductAll
          );
          setLoading(false);
          setPageNo(pageNo + 1);
          new Notify({
            status: "success",
            title: "Success",
            text: "New Product data is Fetched Successfully!",
            effect: "fade",
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: true,
            autotimeout: 3000,
            gap: 20,
            distance: 20,
            type: "outline",
            position: "right top",
          });
        }
      } else {
        new Notify({
          status: "error",
          title: "Error",
          text: "Fetch New Product data limit exceed!",
          effect: "fade",
          speed: 300,
          customClass: null,
          customIcon: null,
          showIcon: true,
          showCloseButton: true,
          autoclose: true,
          autotimeout: 2000,
          gap: 20,
          distance: 20,
          type: "outline",
          position: "right top",
        });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      new Notify({
        status: "error",
        title: e.name,
        text: e.message,
        effect: "fade",
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: "outline",
        position: "right top",
      });
    }
  };

  const fetchLess = async () => {
    try {
      if (pageNo > 1) {
        const products = await showMore(pageNo - 1);
        if (products) {
          setSubjectiveProductAllFeaturesData(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          const SubjectiveProductAll = JSON.stringify(
            products?.Subjective
              ?.corresponding_data_for_3_products_all_features_s
          );
          localStorage.setItem(
            "SubjectiveProductAllFeatures",
            SubjectiveProductAll
          );
          setPageNo(pageNo - 1);
          new Notify({
            status: "success",
            title: "Success",
            text: "New Product data is Fetched Successfully!",
            effect: "fade",
            speed: 300,
            customClass: null,
            customIcon: null,
            showIcon: true,
            showCloseButton: true,
            autoclose: true,
            autotimeout: 3000,
            gap: 20,
            distance: 20,
            type: "outline",
            position: "right top",
          });
        }
      } else {
        new Notify({
          status: "error",
          title: "Error",
          text: "Fetch New Product data limit exceed!",
          effect: "fade",
          speed: 300,
          customClass: null,
          customIcon: null,
          showIcon: true,
          showCloseButton: true,
          autoclose: true,
          autotimeout: 2000,
          gap: 20,
          distance: 20,
          type: "outline",
          position: "right top",
        });
      }
    } catch (e) {
      new Notify({
        status: "error",
        title: e.name,
        text: e.message,
        effect: "fade",
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: "outline",
        position: "right top",
      });
    }
  };

  const fetchRemovedItems = async () => {
    try {
      setItemsLoading(true);
      const products = await showMore("3");
      if (products) {
        setItemsLoading(false);
        setSubjectiveProductAllFeaturesData(
          products?.Subjective?.corresponding_data_for_3_products_all_features_s
        );
        const SubjectiveProductAll = JSON.stringify(
          products?.Subjective?.corresponding_data_for_3_products_all_features_s
        );
        localStorage.setItem(
          "SubjectiveProductAllFeatures",
          SubjectiveProductAll
        );
        new Notify({
          status: "success",
          title: "Success",
          text: "New Product data is Fetched Successfully!",
          effect: "fade",
          speed: 300,
          customClass: null,
          customIcon: null,
          showIcon: true,
          showCloseButton: true,
          autoclose: true,
          autotimeout: 3000,
          gap: 20,
          distance: 20,
          type: "outline",
          position: "right top",
        });
      }
    } catch (e) {
      console.log(e);
      setItemsLoading(false);
      new Notify({
        status: "error",
        title: e.name,
        text: e.message,
        effect: "fade",
        speed: 300,
        customClass: null,
        customIcon: null,
        showIcon: true,
        showCloseButton: true,
        autoclose: true,
        autotimeout: 3000,
        gap: 20,
        distance: 20,
        type: "outline",
        position: "right top",
      });
    }
  };

  const handleProdutDelete = (productId) => {
    let newData = [];
    newData = SubjectiveProductAllFeaturesData?.filter(
      (item) => item?.asin !== productId
    );
    setSubjectiveProductAllFeaturesData(newData);
    fetchRemovedItems();
  };

  let productFilteredDetails;

  const handleFilter = (product) => {
    const topFourFeaturesOrder = topTableFeatures.map((item) => item.feature);

    const filteredDetails = product?.details
      ?.filter((item) => topFourFeaturesOrder.includes(item.feature))
      ?.sort(
        (a, b) =>
          topFourFeaturesOrder.indexOf(a.feature) -
          topFourFeaturesOrder.indexOf(b.feature)
      );

    productFilteredDetails = filteredDetails;
  };

  useEffect(() => {
    let filtereData;
    const topFourFeaturesOrder = topTableFeatures.map((item) => item.feature);
    SubjectiveProductAllFeaturesData?.map((item) => {
      const filteredDetails = item?.details
        ?.filter((item) => topFourFeaturesOrder.includes(item.feature))
        ?.sort(
          (a, b) =>
            topFourFeaturesOrder.indexOf(a.feature) -
            topFourFeaturesOrder.indexOf(b.feature)
        );

      filtereData = filteredDetails;
    });

    const combinedDetails = [];

    filtereData?.map((review) => {
      topTableFeatures?.map((description) => {
        if (review.feature === description.feature) {
          combinedDetails.push(description);
        }
      });
    });

    setFilterFeaturesValue(combinedDetails);
  }, [SubjectiveProductAllFeaturesData, topTableFeatures]);

  let objectiveFeatureValues;

  useEffect(() => {
    objectiveFeatureValues = ObjectiveData?.top_4_features?.map((feature) => {
      const featureValues =
        ObjectiveData?.corresponding_data_for_3_products_all_features_o?.map(
          (product) => ({
            asin: product.asin,
            value: product[feature.feature] || "Data not Available",
            hasData:
              product[feature.feature] === product[feature.feature]
                ? true
                : false,
          })
        );
      const allValuesSame = featureValues.every(
        (val) => val.value === featureValues[0].value
      );
      return {
        feature: feature.feature,
        description: feature.description,
        values: featureValues,
        allValuesSame: allValuesSame,
      };
    });
    setObjectiveFeatureValues(objectiveFeatureValues);
  }, [ObjectiveData]);

  return (
    <div className="mt-2 md:mt-4">
      <Table hoverable ref={tableRef}>
        <Table.Head className="border-b-2 border-black relative">
          <Table.HeadCell className="bg-transparent text-black relative">
            {pageNo > 1 ? (
              <IoIosArrowBack
                size={25}
                className="cursor-pointer absolute right-5 bottom-20 my-2"
                onClick={fetchLess}
              />
            ) : null}
            <p className="text-xl md:text-2xl absolute bottom-5 left-3 w-full flex items-center lowercase">
              <span className="mr-2 capitalize">Ratings</span>
              <CustomToolTip
                size={12}
                content={
                  <div>
                    <span className="capitalize">Ratings </span>
                    for each factor are scored and colored based on the ratio of
                    positive reviews to total reviews.
                  </div>
                }
              />
            </p>
          </Table.HeadCell>
          {SubjectiveProductAllFeaturesData?.map((item) => (
            <Table.HeadCell
              key={item.asin}
              className="bg-transparent capitalize font-light "
            >
              {item?.name ? (
                <div className="pt-4">
                  <div className="w-36 md:w-60 py-2 text-left md:text-center px-0 md:px-4 rounded-lg relative text-sm md:text-lg text-black font-bold">
                    <a href={item.product_link} target="_blank">
                      {item?.name}
                    </a>
                    <RxCross2
                      size={25}
                      color="#D9D9D9"
                      className="text-center absolute -top-3 right-5 cursor-pointer"
                      onClick={() => handleProdutDelete(item?.asin)}
                    />
                  </div>
                  {item.product_image ? (
                    <img
                      src={item.product_image}
                      alt="product-image"
                      className="ml-0 md:ml-10 w-[80px] md:w-[120px]"
                    />
                  ) : (
                    <div className="text-sm w-[80px] md:w-[120px]">
                      No Image
                    </div>
                  )}
                  <a
                    href={item.product_link}
                    target="_blank"
                    className="mt-2 inline-block"
                  >
                    <Button
                      key={item.asin}
                      color="dark"
                      className="-ml-4 md:ml-4 bg-black100 px-0 md:px-3 py-0"
                    >
                      {item.price?.includes("$") ? (
                        <p>
                          {item.price} on{" "}
                          <img
                            src={AmazonlogowhiteImg}
                            width={24}
                            className="inline ml-1"
                            alt="AmazonlogowhiteImg"
                          />{" "}
                        </p>
                      ) : (
                        item.price
                      )}
                    </Button>
                  </a>
                </div>
              ) : null}
            </Table.HeadCell>
          ))}
          <Button
            color="outline"
            className="text-xl capitalize absolute bottom-20 -right-7 md:-right-5"
            onClick={fetchMore}
          >
            {loading ? (
              <div className="flex items-center">
                <FaSpinner className="mr-2" />
                loading...
              </div>
            ) : (
              <>
                <CiCirclePlus size={20} className="mr-1" />
                Show More
              </>
            )}
          </Button>
        </Table.Head>
        <Table.Body className="divide-y">
          {/* Top Three features in first half of the table */}
          <Table.Row>
            <Table.Cell
              colSpan={4}
              className="h-36 overflow-y-auto overflow-x-hidden"
            >
              <div className="relative">
                {FilterFeaturesValue?.map((item, index) => (
                  <Table.Cell
                    key={index}
                    className="whitespace-nowrap font-medium text-gray-900 dark:text-white flex items-start md:items-center border-b md:border-b-0"
                  >
                    <p className="mr-1 md:mr-2 w-36 md:w-auto text-wrap md:mt-1 h-10 md:h-7">
                      {item?.feature.replace(/[_-]/g, " ")}
                    </p>
                    <CustomToolTip size={12} content={item?.description} />
                  </Table.Cell>
                ))}
                <div className="absolute top-4 right-0 w-9/12 grid grid-cols-12 gap-3 md:gap-20">
                  {SubjectiveProductAllFeaturesData?.map(
                    (product, productIndex) => (
                      <div
                        key={`product-${productIndex}`}
                        className="col-span-4"
                      >
                        {handleFilter(product)}
                        {productFilteredDetails?.map((item, itemIndex) => (
                          <div
                            key={`item-${itemIndex}`}
                            className="h-[70px] md:h-14 flex items-start md:items-start mt-[2px] md:mt-1"
                          >
                            <div
                              className={`${
                                Math.floor(
                                  (item.positive /
                                    (item.positive + item.negative)) *
                                    100
                                ) <= 35
                                  ? "bg-red100 text-white font-semibold"
                                  : Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) > 35 &&
                                    Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) <= 50
                                  ? "bg-orange100 text-white font-semibold"
                                  : Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) > 50 &&
                                    Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) <= 60
                                  ? "bg-yellow200 text-white font-semibold"
                                  : Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) > 60 &&
                                    Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) < 80
                                  ? "bg-green200 text-white font-semibold"
                                  : Math.floor(
                                      (item.positive /
                                        (item.positive + item.negative)) *
                                        100
                                    ) <= 100
                                  ? "bg-green100 text-white font-semibold"
                                  : ""
                              } ml-10 md:ml-3 inline-block w-10  text-center py-2 rounded text-black cursor-pointer`}
                              onClick={() =>
                                setOpenModal({
                                  open: true,
                                  product: product,
                                  product_details: item,
                                })
                              }
                            >
                              {Math.floor(
                                (item.positive /
                                  (item.positive + item.negative)) *
                                  100
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="border-none bg-white hover:bg-white dark:border-gray-700 dark:bg-gray-800 text-black">
            <Table.Cell colSpan={4} className="border-b-2 border-black">
              <div className="flex justify-between items-center">
                <div className="flex max-w-md gap-4">
                  <h2 className="text-xl font-semibold">Features & Specs</h2>
                  <Toggle toggle={switch1} setToggle={setSwitch1} />
                </div>
                <div className="">
                  <ShowToggle
                    showFeature={showFeature}
                    setShowFeatures={setShowFeatures}
                  />
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
          {/* ObjectiveData in the second half of the table */}
          <Table.Row className={`${showFeature ? "md:visible" : "hidden"}`}>
            <Table.Cell colSpan={4}>
              <div className="relative w-full max-w-ful top-0 left-0">
                {ObjectiveFeatureValues?.map((item, itemIndex) => (
                  <Table.Cell
                    key={itemIndex}
                    className={`whitespace-nowrap font-medium text-gray-900 dark:text-white flex items-center my-1 ${
                      switch1
                        ? item?.allValuesSame
                          ? ""
                          : "bg-yellow100 hover:bg-yellow100"
                        : null
                    }`}
                  >
                    <p className="mr-1 md:mr-2 w-36 md:w-auto text-wrap">
                      {item.feature.replace(/[_-]/g, " ")}
                    </p>
                    <CustomToolTip size={12} content={item?.description} />
                  </Table.Cell>
                ))}
                <div className="absolute top-4 right-0 w-9/12 md:w-[78%] grid grid-cols-12 gap-10 ">
                  {ObjectiveFeatureValues?.map((product, productIndex) => (
                    <div
                      key={`product-${productIndex}`}
                      className={`col-span-11 flex justify-between`}
                    >
                      {product?.values?.map((item, itemIndex) => (
                        <div
                          key={`item-${itemIndex}`}
                          className={`w-36 text-center  `}
                          style={{ height: "14px" }}
                        >
                          <div className={`rounded text-black cursor-pointer`}>
                            {item?.value}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row className="border-none bg-white dark:border-gray-700 dark:bg-gray-800 text-black ml-10">
            <Table.Cell className="border-t-2 border-black">
              {/* <Button
                color="light"
                className="text-xl capitalize w-36 hidden md:block"
                onClick={fetchRemovedItems}
              >
                {itemsLoading ? (
                  <div className="flex items-center">loading...</div>
                ) : (
                  <>Removed Items</>
                )}
              </Button> */}
              <div className="w-36"></div>
            </Table.Cell>
            {SubjectiveProductAllFeaturesData?.map((item) => (
              <Table.Cell className="border-t-2 border-black" key={item.asin}>
                <a href={item.product_link} target="_blank">
                  <Button
                    key={item.asin}
                    color="dark"
                    className="-ml-4 md:ml-6 bg-black100 px-0 md:px-3 py-0"
                  >
                    {item.price?.includes("$") ? (
                      <p>
                        {item.price} on{" "}
                        <img
                          src={AmazonlogowhiteImg}
                          width={24}
                          className="inline ml-1"
                          alt="AmazonlogowhiteImg"
                        />{" "}
                      </p>
                    ) : (
                      item.price
                    )}
                  </Button>
                </a>
              </Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>
      <Modal
        dismissible
        show={openModal?.open}
        onClose={() => setOpenModal({ open: false })}
        size="7xl"
      >
        <Modal.Body className="custom-scrollbar">
          <ProductModal
            product={openModal.product}
            productDetails={openModal.product_details}
            handleClose={setOpenModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompareTable;
